import React from 'react';

import { Layout } from '../components/layout';
import { MenuLink } from '../components/menu';
import { Title, Paragraph } from '../components/copy';

const Curatele = ({ location }) => (
  <Layout location={location}>
    <div className="mb-4">
      <MenuLink to="/">{'< Terug naar homepagina'}</MenuLink>
    </div>

    <Title>Curatele</Title>

    <Paragraph>
      Curatele is de meest vergaande maatregel van bescherming. Wanneer iemand
      niet meer in staat is om voor zijn/haar persoonlijke en financiële zaken
      zorg te dragen kan hij of zij onder curatele worden gesteld. Diegene die
      onder curatele staat is handelingsonbekwaam, dat wil zeggen dat hij/zij
      niet meer zelfstandig kan beslissen over financiën, verzorging en
      behandeling.
    </Paragraph>

    <Paragraph>
      Curatele is een ingrijpende maatregel op zowel financieel als
      zorginhoudelijk gebied en dient daarom uitvoerig met betrokkenen en diens
      omgeving besproken te worden. Voor aanmelding gaan wij dan ook uitgebreid
      met u in gesprek over wat u van ons kunt verwachten. Het uitgangspunt
      hierbij is dat het belang van de onder curatele gestelde voorop staat.
    </Paragraph>

    <Paragraph>
      Voor aanmelding kunt u contact opnemen met één van ons.
    </Paragraph>
  </Layout>
);

export default Curatele;
